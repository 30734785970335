import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

// import p1 from 'assests/Photos/ClassAssembly/2024/4B/1.jpg';
// import p2 from 'assests/Photos/ClassAssembly/2024/4B/2.jpg';
// import p3 from 'assests/Photos/ClassAssembly/2024/4B/3.jpg';
// import p4 from 'assests/Photos/ClassAssembly/2024/4B/4.jpg';






// import p7 from 'assests/Photos/ClassAssembly/6D/7.jpg';





import { Typography } from '@mui/material';
import Container from 'components/Container';
import ClassAssembly2024 from '../ClassAssembly2024';
import Archive2022 from '../ClassAssembly2023/Archive2022';

const ClassAssembly4B2024 = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });


  const [config, setConfig] = useState({ base_image_url: '' });
  useEffect(() => {
    fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
      .then(response => response.json())
      .then(data => setConfig(data))
      .catch(error => console.error('Error fetching config:', error));
  }, []);
  
  const p1 = `${config.base_image_url}/home/events-activities/class-assembly/4B/1.webp`;
  const p2 = `${config.base_image_url}/home/events-activities/class-assembly/4B/2.webp`;
  const p3 = `${config.base_image_url}/home/events-activities/class-assembly/4B/3.webp`;
  const p4 = `${config.base_image_url}/home/events-activities/class-assembly/4B/4.webp`;

  
  const photos = [
    {
      src: p1,
      source: p1,
      rows: 1,
      cols: 1,
    },
    {
        src: p2,
        source: p2,
        rows: 1,
        cols: 1,
      },
    {
      src: p3,
      source:p3,
      rows: 1,
      cols: 1,
    },
   
    {
      src: p4,
      source: p4,
      rows: 1,
      cols: 1,
    }
    // {
    //     src: p5,
    //     source: p5,
    //     rows: 1.5,
    //     cols: 2,
    //   },
     

    // {
    //   src: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
    //   source: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
    //   rows: 1,
    //   cols: 1,
    // },
  ];

  return (
    <Main>
        <Container>
    <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center'>
      Independence Day - Unity in Diversity
        </Typography>
        <Typography
        fontSize = "x-small"
        ><br/><br/>
 Class: 4B Date: 19 & 20 August 2024

        </Typography>
        {/* <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
   “Friends are the charming gardeners who make our souls blossom"

<br/>
        </Typography> */}

        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        The students of Class 4B presented their assembly on the topic “Unity in Diversity” on Monday, 19 September 2024 and Tuesday, 20 September 2024. The assembly commenced with a thought that inspired everybody to be proud of India and strive hard to make it vibrant, strong and supreme.  A brief introduction about the importance of Unity in Diversity in India was shared.
        <br></br>
        As a part of Independence Day Celebration, the junior choir  of our school presented a patriotic song
        On Monday, 19 August 2024.
        On Tuesday, 20 August 2024, the patriotic fervour with the theme “Unity in Diversity” continued with a dance performance for the song “Hum Hain Hindustani” which was sung melodiously by the class.  An additional dance was performed by the students of Pre-Primary which impressed everyone and also enhanced the spirit of patriotism. 
        <br></br>
        The assembly concluded with a strong message to each and everyone to respect the uniqueness of India
        and to know that Unity in Diversity promotes acceptance, understanding and allows individuals of different backgrounds to live and work together harmoniously. 

        <br></br>
        
        </Typography>
      </Box>
      <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
   “OUR UNITY IS OUR STRENGTH AND OUR DIVERSITY IS OUR POWER. 
   <br/>
        </Typography>

    <Box>
        <ImageList
          variant="quilted"
          cols={2}
          rowHeight={isMd ? 200 : 100}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              /> 
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>
    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <ClassAssembly2024 />
             </Box>
             <Box marginBottom={4}>
               <Archive2022 />
             </Box>
           {/* <SidebarNewsletter /> */}
         </Grid>
    </Grid> 
    </Container>
    </Main>
   
  );
};

export default ClassAssembly4B2024;